body {
  font-family: 'Lato', sans-serif;
  background: linear-gradient(to top right, #bf7a6b 0%, #e6d8a7 100%);
  margin: 0;
  padding: 0;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth-container {
  width: 80vw;
  max-width: 400px;
  margin: 20px;
  padding: 30px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

h2 {
  color: #333;
  font-size: 2.5rem;
  margin-bottom: 30px;
  text-align: center;
}

.auth-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.auth-input {
  width: 100%;
  padding: 12px;
  border: 2px solid #4daf7c;
  border-radius: 5px;
  font-size: 1rem;
  box-sizing: border-box;
}

.auth-button {
  width: 100%;
  padding: 12px;
  background: #4daf7c;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;
  transition: background 0.3s ease;
}

.auth-button:hover {
  background: #3c9d6d;
}

.auth-link {
  text-align: center;
  margin-top: 15px;
  font-size: 0.9rem;
}

.auth-link a {
  color: #4daf7c;
  text-decoration: none;
}

.auth-link a:hover {
  text-decoration: underline;
}

.auth-error,
.auth-message {
  text-align: center;
  margin-top: 15px;
  font-size: 0.9rem;
}

.auth-error {
  color: #dc3545;
}

.auth-message {
  color: #28a745;
}

@media (max-width: 480px) {
  .auth-container {
    margin: 10px;
    padding: 20px;
  }
}