.download-link {
    width: 100%;
    padding: 12px;
    background: #4daf7c;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: 700;
    cursor: pointer;
    transition: background 0.3s ease;
    margin-top: 10px;
}

.download-link:hover {
    background: #3c9d6d;
}

.download-link.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}


