.file-upload-wrapper {
    position: relative;
    width: 100%;
  }
  
  .file-upload-wrapper input[type="text"] {
    width: 100%;
    padding: 10px;
    border: 2px solid #4daf7c;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .file-upload-wrapper input[type="file"] {
    display: none;
  }