.highlights-options {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-bottom: 1rem;
}

.mood-selector,
.duration-selector,
.speaker-selection {
    display: flex;
    flex-direction: column;
}

.highlights-options label {
    margin-bottom: 0.5rem;
    font-weight: bold;
}

.highlights-options select {
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: white;
    cursor: pointer;
}

.highlights-options select:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}