.upgrade-banner {
    background-color: #f0f8ff;  /* Light blue background */
    border: 1px solid #4CAF50;  /* Green border */
    border-radius: 5px;
    padding: 10px;
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .upgrade-banner p {
    margin: 0;
    font-size: 14px;
  }
  
  .upgrade-button {
    background-color: #4CAF50;
    color: white;
    padding: 8px 15px;
    text-align: center;
    text-decoration: none;
    border-radius: 3px;
    font-size: 14px;
    transition: background-color 0.3s;
  }
  
  .upgrade-button:hover {
    background-color: #45a049;
  }