.file-upload-container {
    width: 80vw;
    max-width: 400px;
    margin: 20px;
    padding: 30px;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }
  
  .file-upload-container h1 {
    color: #333;
    font-size: 2.5rem;
    margin-bottom: 30px;
    text-align: center;
  }
  
  .user-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 5px;
  }
  
  .logout-button {
    padding: 5px 15px;
    background: #dc3545;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .logout-button:hover {
    background: #c82333;
  }
  
  .overview {
    text-align: center;
    color: #666;
    margin-bottom: 20px;
    font-size: 15px;
  }
  
  .file-selector {
    width: 100%;
    padding: 12px;
    border: 2px solid #4daf7c;
    border-radius: 5px;
    font-size: 1rem;
    box-sizing: border-box;
    margin-bottom: 15px;
  }
  
  .segmented-control {
    display: flex;
    border: 2px solid #4daf7c;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 15px;
  }
  
  .segmented-control button {
    flex: 1;
    padding: 10px;
    border: none;
    background: #fff;
    color: #4daf7c;
    font-weight: 700;
    transition: background 0.3s ease, color 0.3s ease;
    cursor: pointer;
  }
  
  .segmented-control button.active {
    background: #4daf7c;
    color: #fff;
  }
  
  .disclaimer {
    text-align: center;
    color: #666;
    margin-top: 15px;
    font-size: 0.9rem;
  }
  
  .process-button {
    width: 100%;
    padding: 12px;
    background: #4daf7c;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: 700;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .process-button:hover:not(:disabled) {
    background: #3c9d6d;
  }
  
  .process-button:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
  
  .progress-bar {
    width: 100%;
    height: 10px;
    background: #e0e0e0;
    border-radius: 5px;
    overflow: hidden;
    margin-top: 20px;
  }
  
  .progress {
    height: 100%;
    background: #4daf7c;
    transition: width 0.3s ease;
  }
  
  .warning-message {
    background-color: #fff3cd;
    border: 1px solid #ffeeba;
    color: #856404;
    padding: 20px 10px;
    margin: 10px 0;
    border-radius: 4px;
    text-align: left;
  }

  .warning-message ul {
    margin: 0;
    padding-left: 20px;
  }
  
  .error-message {
    background-color: #ffebee;
    color: #c62828;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 15px;
    border: 1px solid #ef9a9a;
  }

  .error-message p {
    margin: 0;
  }
  
  @media (max-width: 480px) {
    .file-upload-container {
      margin: 10px;
      padding: 20px;
    }
  }