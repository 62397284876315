  .message {
    text-align: center;
    margin: 15px;
    color: #666;
  }

  .message-display {
    text-align: center;
    margin-top: 15px;
    font-weight: bold;
    color: #4daf7c;
  }